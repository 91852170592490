// Imports
// ------
import React, { useEffect, useRef } from 'react';
import { tickerSize } from '@states/tickerSize';
import { action } from 'mobx';

// Styles
// ------
import { Jacket, Sweater, Text } from './styles';

// Component
// ------
const Ticker = ({ std }) => {
	const msg = `THE FUTURE OF DIGITAL IS PHYSICAL  •  THE FUTURE OF DIGITAL IS PHYSICAL  •  `;
	const ticker = useRef();

	useEffect(
		action(() => {
			tickerSize.height = ticker.current.offsetHeight;
		}, [])
	);

	return (
		<Jacket ref={ticker} isNormal={!std} className="slider-bar">
			<Sweater ariaHidden='true'>
				<Text>
					{msg + msg + msg + msg + msg + msg + msg + msg + msg}
				</Text>
			</Sweater>
		</Jacket>
	);
};

export default React.memo(Ticker);
