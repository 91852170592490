// Imports
// ------
import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

// Images
// ------
import logo from '@images/logoIcon.svg';

// Component
// ------
const SEO = ({ title, desc, image }) => {
	const schemaOrgWebPage = {
		'@context': 'https://schema.org',
		'@type': 'Corporation',
		name: 'Fountain',
		alternateName: 'Fountain',
		url: 'https://yourfountainapp.com',
		logo: { logo },
		sameAs: 'https://www.linkedin.com/company/your-fountain',
	};

	return (
		<StaticQuery
			query={graphql`
				query SEOQuery {
					dato: datoCmsSite {
						seo: globalSeo {
							fallback: fallbackSeo {
								title
								desc: description
								image {
									url
								}
							}
							suf: titleSuffix
						}
					}
				}
			`}
			render={(data) => (
				<Helmet
					title={
						title
							? title + data.dato.seo.suf
							: data.dato.seo.fallback.title
					}
				>
					<html lang='en' />
					<meta
						name='description'
						content={desc ? desc : data.dato.seo.fallback.desc}
					/>
					<meta
						property='og:title'
						content={
							title
								? title + data.dato.seo.suf
								: data.dato.seo.fallback.title
						}
					/>
					<meta
						property='og:description'
						content={desc ? desc : data.dato.seo.fallback.desc}
					/>
					<meta
						property='og:image'
						content={
							image ? image.url : data.dato.seo.fallback.image.url
						}
					/>

					<script type='application/ld+json'>
						{JSON.stringify(schemaOrgWebPage)}
					</script>
				</Helmet>
			)}
		/>
	);
};

export default React.memo(SEO);
