// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, P } from '@tackl';

// Exports
// ------
const marquee = keyframes`
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-50%);
    }
`;

export const Jacket = styled.div(
	(props) => css`
		position: relative;
		overflow-x: hidden;
		width: 100%;
		max-width: 100%;
		height: 4.4rem;
		border-top: 1px solid
			${props.isNormal ? props.theme.bc2 : props.theme.bc1};
		border-bottom: 1px solid
			${props.isNormal ? props.theme.bc2 : props.theme.bc1};
		color: ${props.isNormal ? props.theme.bc2 : props.theme.bc1};
		overflow: hidden;
		pointer-events: none;
		user-select: none;

		${breakup.large` height: 4.7rem; `}
	`
);

export const Sweater = styled.div`
	position: absolute;
	white-space: nowrap;
	will-change: transform;
	animation: ${marquee} 24s linear infinite;
`;

export const Text = styled(P)`
	color: inherit;
	padding: 1.2rem 0;
`;
